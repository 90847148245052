@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");

@layer base {
	body {
		@apply font-[Raleway];
	}
	li {
		@apply px-4;
		@apply cursor-pointer;
	}
}

.content-div {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 250px;
}

.content-div:hover {
	background-image: linear-gradient(
		to right,
		rgba(112, 157, 255, 0.8),
		hsla(242, 74%, 61%, 0.8)
	) !important;
}

.highlight {
	color: blue; /* Change the color or add other styles as needed */
	cursor: pointer;
  }
	footer {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		color: white;
		text-align: center;
	}
  
/* Media query for smaller screens (e.g., mobile devices) */
@media screen and (max-width: 767px) {
    .logo-image {
        max-width: 17%; /* Adjust the size as needed */
        /* You can also apply other styles to further control the logo's appearance on mobile */
    }
}


